import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { breakpointDown, styled } from '@moonpig/launchpad-utils'
import { Text, Flex, IconButton } from '@moonpig/launchpad-components'
import { IconSystemCross } from '@moonpig/launchpad-assets'
import { RecentSearchItemType, RecentSearchType } from '../types'
import { useLocaleText } from '../../locale'

const StyledRecentSearch = styled.li`
  ${s({ px: 5, py: 4, color: 'colorTextBody' })}
  cursor: pointer;
  width: 320px;
  ${breakpointDown('md')} {
    width: 100%;
  }
  &:hover {
    ${s({ bgcolor: 'colorBackground02' })}
  }
`

type RecentSearchProps = {
  item: RecentSearchType
  itemIndex: number
  getItemProps: (props: {
    itemIndex: number
    item: RecentSearchItemType
  }) => Record<string, unknown>
  onRemoveItem: (item: RecentSearchType) => void
} & React.HTMLProps<HTMLElement>

export const RecentSearch: FC<RecentSearchProps> = ({
  item,
  itemIndex,
  getItemProps,
  onRemoveItem,
}) => {
  const t = useLocaleText()
  const capitalizedSearchTerm = `${item.searchTerm[0].toUpperCase()}${item.searchTerm?.slice(
    1,
  )}`

  return (
    <StyledRecentSearch
      tabIndex={0}
      aria-label={`${t('search.search_for')} ${capitalizedSearchTerm}`}
      {...getItemProps({
        itemIndex,
        item: { ...item, itemType: 'recent' },
      })}
    >
      <Flex flexDirection="row" justifyContent="space-between">
        <Text color="colorTextBody">{capitalizedSearchTerm}</Text>
        <IconButton
          label={`${t('search.clear_recent_search')} ${capitalizedSearchTerm}`}
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
            onRemoveItem(item)
          }}
          icon={IconSystemCross}
          width={'20px'}
          height={'20px'}
          marginX={8}
          marginTop={2}
          data-testid="lp-nav-search-recent-remove-item"
        />
      </Flex>
    </StyledRecentSearch>
  )
}
