/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { FC, useState, memo, createRef, useEffect, useRef } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import {
  Box,
  ScreenReaderOnly,
  Link as LinkComponent,
} from '@moonpig/launchpad-components'
import {
  styled,
  breakpoint,
  breakpointDown,
  isBrowser,
} from '@moonpig/launchpad-utils'
import { IconSystemChevronRight as IconChevron } from '@moonpig/launchpad-assets'

import {
  NAV_BP,
  COLOR_KEYLINE,
  COLOR_LINK_HOVER,
  KEYLINE_SIZE_PX,
  ICON_SIZE_PX,
  TRANSITON_TIME_MS,
  MOBILE_NAV_WIDTH_PX,
  COLOR_DEFAULT_TEXT,
} from '../constants'
import { DropdownItemProps } from './types'
import { useLocaleText } from '../locale'

type SubDropdownProps = {
  subDropdown: {
    title: string
    items: DropdownItemProps[]
    buttonAs?:
      | keyof JSX.IntrinsicElements
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | React.ComponentType<React.PropsWithChildren<any>>
  }
  dropdownIndex: number
  id: string
  focusedItemIndex: number
  onLinkKeyDown: (e: React.KeyboardEvent) => void
  forceRenderAllNavNodes?: boolean
  isDesktopNav: boolean
}

const { useLayoutEffect } = React

const StyledSubDropdownItems = styled.div`
  ${breakpoint(NAV_BP)} {
    height: auto !important;
  }

  ${breakpointDown(NAV_BP)} {
    overflow: hidden;
    transition: height ${TRANSITON_TIME_MS};
  }
`

const useBrowserAnimateHeight = (
  dropdownNode: React.MutableRefObject<HTMLElement | null>,
  dropdownContentNode: React.MutableRefObject<HTMLElement | null>,
  isOpen: boolean,
) => {
  useLayoutEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const node = dropdownNode.current!
    const applyHeight = (height: string) => {
      node.style.height = height
    }
    let setAutoFrameId = 0

    const setHeightFrameId = window.requestAnimationFrame(() => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const { height } = dropdownContentNode.current!.getBoundingClientRect()
      applyHeight(`${height}px`)
      if (!isOpen) return window.setTimeout(() => applyHeight('0px'), 0)
      return undefined
    })

    const setAuto = () => {
      setAutoFrameId = window.requestAnimationFrame(() => applyHeight('auto'))
    }
    if (isOpen) {
      node.addEventListener('transitionend', setAuto)
    }

    return () => {
      node.removeEventListener('transitionend', setAuto)
      window.cancelAnimationFrame(setHeightFrameId)
      window.cancelAnimationFrame(setAutoFrameId)
    }
  }, [dropdownContentNode, dropdownNode, isOpen])
}

const noop = () => {}

const useAnimateHeight = isBrowser() ? useBrowserAnimateHeight : noop

const SubDropdownItems: FC<
  React.PropsWithChildren<{
    dropdownIndex: number
    isOpen: boolean
  }>
> = ({ dropdownIndex, isOpen, children }) => {
  const dropdownNode = React.useRef(null)
  const dropdownContentNode = React.useRef(null)

  useAnimateHeight(dropdownNode, dropdownContentNode, isOpen)

  return (
    <StyledSubDropdownItems
      ref={dropdownNode}
      data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-items`}
    >
      <ul role="list" ref={dropdownContentNode}>
        {children}
      </ul>
    </StyledSubDropdownItems>
  )
}

const StyledSubDropdownTitle = styled.h3`
  ${s({
    px: 6,
    py: 5,
  })}
  position: relative;
  margin-bottom: 0;
  color: ${COLOR_DEFAULT_TEXT};

  ${breakpoint(NAV_BP)} {
    ${s({
      px: 0,
      pt: 0,
      pb: 4,
      mb: 4,
      typography: 'typeBodyCaption',
      fontWeight: 700,
    })}
    border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  }

  ${breakpointDown(NAV_BP)} {
    ${s({ typography: 'typeButtonLabel' })}

    &:hover,
    &:focus {
      color: ${COLOR_LINK_HOVER};
    }
  }
`

const StyledSubDropdownToggle = styled.button<{ isOpen: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: ${MOBILE_NAV_WIDTH_PX};

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledSubDropdownItem = styled.li`
  ${breakpointDown(NAV_BP)} {
    &:last-child {
      ${s({ pb: 6 })}
    }
  }
`

const StyledSubDropdownLink = styled(LinkComponent)`
  display: inline-block;
  color: inherit;
  ${s({
    py: { xs: 5, [NAV_BP]: 3 },
    pl: { xs: 12, [NAV_BP]: 0 },
    typography: 'typeBodyCaption',
  })}

  &:hover,
  &:focus {
    text-decoration: underline;
    ${s({ color: 'colorInteractionButton' })}
  }

  ${breakpointDown(NAV_BP)} {
    display: block;
  }
`

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledChevron = styled(({ isOpen, ...props }) => (
  <IconChevron {...props} />
)).attrs(({ isOpen }) => ({
  style: {
    transform: `translateY(-50%) rotate(${isOpen ? '-' : ''}90deg)`,
  },
}))<{ isOpen: boolean }>`
  color: ${COLOR_DEFAULT_TEXT};
  position: absolute;
  top: 50%;
  right: 12px;
  width: ${ICON_SIZE_PX};
  height: ${ICON_SIZE_PX};
  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledSubDropdown = styled.div`
  ${breakpointDown(NAV_BP)} {
    border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  }
`

const Link: FC<
  React.PropsWithChildren<{
    href: string
    dropdownIndex: number
    shouldFocus: boolean
    onKeyDown: (e: React.KeyboardEvent) => void
  }>
> = ({ href, dropdownIndex, shouldFocus, onKeyDown, children }) => {
  const node = createRef<HTMLAnchorElement>()

  useEffect(() => {
    if (shouldFocus && node.current) {
      node.current.focus()
    }
  }, [shouldFocus, node])
  return (
    <StyledSubDropdownLink
      href={href}
      ref={node}
      onKeyDown={onKeyDown}
      onBlur={e => e.preventDefault()}
      data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-link`}
    >
      {children}
    </StyledSubDropdownLink>
  )
}

export const SubDropdown: FC<React.PropsWithChildren<SubDropdownProps>> = memo(
  ({
    subDropdown: { title, items, buttonAs },
    dropdownIndex = 0,
    focusedItemIndex,
    onLinkKeyDown,
    forceRenderAllNavNodes,
    isDesktopNav,
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [renderMobileItems, setRenderMobileItems] = useState(false)

    const timeout = useRef<number | undefined>(undefined)

    const t = useLocaleText()

    useEffect(() => {
      const REMOVE_NODES_TIMEOUT_MS = 1000

      if (isDesktopNav) return

      if (isOpen) {
        clearTimeout(timeout.current)
        setRenderMobileItems(true)
      } else {
        timeout.current = window.setTimeout(() => {
          setRenderMobileItems(false)
        }, REMOVE_NODES_TIMEOUT_MS)
      }
    }, [setRenderMobileItems, isOpen, isDesktopNav])

    return (
      <Box as="li" width={{ xs: 1, [NAV_BP]: 1 / 6 }}>
        <StyledSubDropdown>
          <StyledSubDropdownTitle
            data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-title`}
          >
            <StyledSubDropdownToggle
              isOpen={isOpen}
              as={buttonAs}
              data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-toggle`}
              aria-label={`${
                isOpen
                  ? t('mega_nav.sub_dropdown.close', title)
                  : t('mega_nav.sub_dropdown.open', title)
              }`}
              tabIndex={-1}
              onClick={() => {
                setIsOpen(!isOpen)
              }}
              role="button"
            >
              <StyledChevron
                data-testid={`lp-nav-sub-dropdown-${dropdownIndex}-chevron`}
                isOpen={isOpen}
                aria-hidden
              />
            </StyledSubDropdownToggle>
            <span>{title}</span>
            <ScreenReaderOnly>
              {t('mega_nav.sub_dropdown.category')}
            </ScreenReaderOnly>
          </StyledSubDropdownTitle>
          <SubDropdownItems dropdownIndex={dropdownIndex} isOpen={isOpen}>
            {isOpen ||
            renderMobileItems ||
            forceRenderAllNavNodes ||
            isDesktopNav
              ? items.map(({ href, label }, index) => (
                  // eslint-disable-next-line react/jsx-indent
                  <StyledSubDropdownItem key={label}>
                    <Link
                      href={href}
                      onKeyDown={onLinkKeyDown}
                      shouldFocus={index === focusedItemIndex}
                      dropdownIndex={dropdownIndex}
                    >
                      {label}
                    </Link>
                  </StyledSubDropdownItem>
                ))
              : null}
          </SubDropdownItems>
        </StyledSubDropdown>
      </Box>
    )
  },
)

SubDropdown.displayName = 'SubDropdown'
