import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { isLoggedIn } from '@moonpig/web-core-auth'
import { isLocalStorageAvailable } from '@moonpig/web-core-utils'
import { ScreenViewScreenData } from './types'
import { sanitizeStrings } from './helpers/sanitizeStrings'
import { getCustomerType } from './helpers/getCustomerType'

type BasketData = {
  id: string
  size: number
}

type UserData = {
  customer_id?: string
  is_logged_in: boolean
  lifetime_order_count?: number
  customer_type?: string
  login_method: undefined | string
  email?: string
}

type Product = {
  product_brand: string
  product_category?: string
  product_id: string
  product_name?: string
  product_variant?: string
  product_price?: number
  product_quantity: number
  bundle_id?: string
  group_card_id?: string
  design_id?: string
  project_id?: string
  product_orientation?: string
}

type CreateScreenViewProperties = {
  basketData?: BasketData
  screenClass: string
  screenName: string
  screenData: ScreenViewScreenData
  productData?: Product[]
}

export const createScreenViewEvent = ({
  basketData,
  screenClass,
  screenName,
  screenData,
  productData,
}: CreateScreenViewProperties) => {
  const userLoggedIn = isLoggedIn(getBrowserCookies())

  const user_data: UserData = {
    customer_id: undefined,
    is_logged_in: userLoggedIn,
    lifetime_order_count: undefined,
    customer_type: undefined,
    login_method: undefined,
    email: undefined,
  }

  if (userLoggedIn && isLocalStorageAvailable()) {
    const userDataInLocalStorage = localStorage.getItem('mnpg_ga_user_data')
    const userDataExists = userDataInLocalStorage
      ? JSON.parse(userDataInLocalStorage)
      : undefined
    if (userDataExists) {
      user_data.login_method = 'email'
      user_data.customer_id = userDataExists.id
      user_data.lifetime_order_count = userDataExists.totalCount
      user_data.customer_type = getCustomerType(userDataExists.totalCount)
    }
  }

  const product_data = productData
    ? productData.map(product => ({
        product_brand: product.product_brand.toLowerCase(),
        product_category: sanitizeStrings(product.product_category),
        product_id: product.product_id.toLowerCase(),
        product_name: sanitizeStrings(product.product_name),
        product_variant: sanitizeStrings(product.product_variant),
        product_price: product.product_price,
        product_quantity: product.product_quantity,
        bundle_id: product.bundle_id,
        group_card_id: product.group_card_id,
        design_id: product.design_id,
        project_id: product.project_id,
        product_orientation: product.product_orientation,
      }))
    : undefined

  const { documentReferrer, documentTitle, documentUrl, renderType } =
    screenData
  return {
    event: 'screen_view',
    basket_data: {
      id: basketData?.id ? basketData.id.toLowerCase() : undefined,
      size: typeof basketData?.size === 'number' ? basketData.size : undefined,
    },
    screen_type: sanitizeStrings(screenClass),
    screen_name: sanitizeStrings(screenName).substring(0, 100).trim(),
    user_data,
    screen_data: {
      // Web only //
      document_referrer: documentReferrer,
      document_title: documentTitle,
      document_url: documentUrl,
      render_type: renderType,
    },
    product_data,
    // Web only //
    content_data: undefined,
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
    // For GTM //
    application_data: {
      datalayer_version: 1,
    },
  }
}
