/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable react/no-array-index-key */
import { Box, Link } from '@moonpig/launchpad-components'
import React, { FC } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { MegaNavItemProps } from './types'

const StyledHiddeNavWrapper = styled(Box)<{
  isOpen: boolean
}>`
  ${({ isOpen }) => {
    return `
    display: ${isOpen ? 'none' : 'block'};
  `
  }}
`
type SEOHiddenNavItemsProps = {
  items: MegaNavItemProps['dropdown']
  isOpen: boolean
}

export const SEOHiddenNavItems: FC<
  React.PropsWithChildren<SEOHiddenNavItemsProps>
> = ({ items, isOpen }) => (
  <>
    {items.map((subDropdown, subDropdownIndex) => {
      return (
        <StyledHiddeNavWrapper
          isOpen={isOpen}
          key={`${subDropdown.title}-${subDropdownIndex}`}
        >
          <h3 data-testid={`lp-nav-dropdown-${subDropdownIndex}-show-nodes`}>
            {subDropdown.title}
          </h3>
          <ul role="list">
            {subDropdown.items.map((item, index) => {
              return (
                <li key={`${item.href}-${item.label}`}>
                  <Link
                    href={item.href}
                    data-testid={`lp-nav-sub-dropdown-${index}-show-nodes-link`}
                  >
                    {item.label}
                  </Link>
                </li>
              )
            })}
          </ul>
        </StyledHiddeNavWrapper>
      )
    })}
  </>
)
