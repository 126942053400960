import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'

const StyledInput = styled.input`
  width: 100%;
  margin: 24px 0;
  padding: 12px;
  border-radius: 8px;
  ${s({
    fontSize: { xs: '16px', md: '24px' },
  })};
`

export const SearchInput: React.FC<{
  placeholder?: string
  onChange: (value: string) => void
}> = ({ placeholder = '', onChange }) => {
  return (
    <StyledInput
      type="text"
      placeholder={placeholder}
      onChange={({ target }) => onChange(target.value)}
    />
  )
}
