import {
  Occasion,
  Relationship,
} from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { createLocaleTextHook } from '@moonpig/web-core-locale-text'
import { DATE_TEXT } from './date.locale'
import { SOFT_OPT_IN_REMINDER_TEXT } from '../components'
import { createReminderHeadingText, createReminderDateText } from '../utils'
import { OccasionsLocale, RelationshipsLocale } from '../constants'

export const REMINDER_TEXT = {
  'common.reminder_card.heading': {
    'en-GB': createReminderHeadingText('en-GB'),
    'nl-NL': createReminderHeadingText('nl-NL'),
  },
  'common.reminder.select': {
    'en-GB': 'Select',
    'nl-NL': 'Selecteer',
  },
  'common.reminder.occasion': {
    'en-GB': ({ occasion }: { occasion: Occasion }) =>
      OccasionsLocale[occasion] ? OccasionsLocale[occasion]['en-GB'] : '',
    'nl-NL': ({ occasion }: { occasion: Occasion }) =>
      OccasionsLocale[occasion] ? OccasionsLocale[occasion]['nl-NL'] : '',
  },
  'common.reminder.relationship': {
    'en-GB': ({ relationship }: { relationship: Relationship }) =>
      RelationshipsLocale[relationship]
        ? RelationshipsLocale[relationship]['en-GB']
        : '',
    'nl-NL': ({ relationship }: { relationship: Relationship }) =>
      RelationshipsLocale[relationship]
        ? RelationshipsLocale[relationship]['nl-NL']
        : '',
  },
  'common.reminder_card.shop': {
    'en-GB': 'Shop',
    'nl-NL': 'Bestellen',
  },
  'common.reminder_card.today': {
    'en-GB': 'Today',
    'nl-NL': 'Vandaag',
  },
  'common.reminder_card.tomorrow': {
    'en-GB': 'Tomorrow',
    'nl-NL': 'Morgen',
  },
  'common.reminder_card.christmas': {
    'en-GB': 'Christmas',
    'nl-NL': 'Kerstmis',
  },
  'common.reminder_card.for': {
    'en-GB': 'for',
    'nl-NL': 'voor',
  },
  'common.reminder_card.days_until': {
    'en-GB': ({ daysUntilEvent }: { daysUntilEvent: number }) =>
      `In ${daysUntilEvent} days`,
    'nl-NL': ({ daysUntilEvent }: { daysUntilEvent: number }) =>
      `Over ${daysUntilEvent} dagen`,
  },
  'common.reminder_form.error': {
    'en-GB':
      'Error retrieving occasions. Please refresh the page and try again.',
    'nl-NL':
      'Fout bij het ophalen van de gelegenheden. Laad de pagina opnieuw en probeer het nogmaals.',
  },
  'common.reminder_form.name_error': {
    'en-GB': 'Name must be between 1 and 255 characters',
    'nl-NL': 'De naam mag niet langer zijn dan 255 tekens',
  },
  'common.reminder_form.missing_field_error': {
    'en-GB': ({ fieldName }: { fieldName: string }) => {
      return `Please select a ${fieldName} for the reminder`
    },
    'nl-NL': /* istanbul ignore next */ ({
      fieldName,
    }: {
      fieldName: string
    }) => {
      return `Voeg een ${fieldName} toe voor het moment`
    },
  },
  'common.reminder_form.relationship_error': {
    'en-GB': 'Please select a relationship for the reminder',
    'nl-NL': 'Selecteer een relatie voor het moment',
  },
  'common.reminder_form.occasion_error': {
    'en-GB': 'Please select an occasion for the reminder',
    'nl-NL': 'Selecteer het moment waarvoor je herinnerd wilt worden',
  },
  'common.reminder_form.date_error': {
    'en-GB': 'Please enter a valid date',
    'nl-NL': 'Voer een geldige datum in',
  },
  'common.reminder_form.they_are_my': {
    'en-GB': 'They Are My',
    'nl-NL': 'Is/zijn mijn',
  },
  'common.reminder_form.optional': {
    'en-GB': '(optional)',
    'nl-NL': '(Optioneel)',
  },
  'common.reminder_form.guess': {
    'en-GB': 'Go with your best guess',
    'nl-NL': 'Niet zeker? Je kan de datum later nog aanpassen.',
  },
  'common.reminder_form.their_name': {
    'en-GB': 'Their Name',
    'nl-NL': 'Naam',
  },
  'common.reminder_form.name_placeholder': {
    'en-GB': 'e.g. Someone Special',
    'nl-NL': 'bijv. Iemand Speciaal',
  },
  'common.reminder_form.christmas_name': {
    'en-GB': 'Name for Christmas Reminder',
    'nl-NL': 'Naam voor kerstherinnering',
  },
  'common.reminder_form.occasion': {
    'en-GB': 'Occasion',
    'nl-NL': 'Gelegenheid',
  },
  'common.reminder_form.day': {
    'en-GB': 'Day',
    'nl-NL': 'Dag',
  },
  'common.reminder_form.month': {
    'en-GB': 'Month',
    'nl-NL': 'Maand',
  },
  'common.reminder_form.loading_relationships': {
    'en-GB': 'loading relationships',
    'nl-NL': 'relaties aan het laden',
  },
  'common.reminder_form.loading_occasions': {
    'en-GB': 'loading occasions',
    'nl-NL': 'gelegenheden aan het laden',
  },
  'common.reminder_card.date': {
    'en-GB': createReminderDateText('en-GB'),
    'nl-NL': createReminderDateText('nl-NL'),
  },
  'common.reminder_form.christmas_reminder_prompt': {
    'en-GB': ({
      name,
      emphasisedTextColour,
    }: {
      name?: string
      emphasisedTextColour: string
    }) =>
      `<strong>Add a <span style="color:${emphasisedTextColour}">Christmas Reminder</span>${
        name ? ` for ${name}` : ''
      }!</strong>`,
    'nl-NL': ({
      name,
      emphasisedTextColour,
    }: {
      name?: string
      emphasisedTextColour: string
    }) =>
      `<strong>Voeg een <span style="color:${emphasisedTextColour}">kerstherinnering toe</span>${
        name ? ` voor ${name}` : ''
      }!</strong>`,
  },
  'common.reminder_notification.view_all': {
    'en-GB': 'View All Reminders',
    'nl-NL': 'Bekijk alle momenten',
  },
  'common.reminder_modal_create_cta': {
    'en-GB': 'Create Reminder',
    'nl-NL': 'Voeg een moment toe',
  },
  'common.reminder_modal_success': {
    'en-GB': ({
      name,
      reminderCount,
    }: {
      name: string
      reminderCount: number
    }) => `${name}'s Reminder${reminderCount > 1 ? 's' : ''} Saved!`,
    'nl-NL': 'Moment is opgeslagen!',
  },
  'common.reminder_modal_aria_label_close': {
    'en-GB': 'Close',
    'nl-NL': 'Sluiten',
  },
  'common.reminders_card_error_title': {
    'en-GB': 'Please Try Again.',
    'nl-NL': 'Probeer het opnieuw.',
  },
  'common.reminders_card_error_text': {
    'en-GB': "Oops, that didn't save this time.",
    'nl-NL': 'Oeps, het is niet opgeslagen.',
  },
  'common.reminders_quick_add_save_failed_error': {
    'en-GB':
      'Oops! Something went wrong adding your reminder. Please try again!',
    'nl-NL':
      'Oeps! Er ging iets fout tijdens het toevoegen van je moment. Probeer het even opnieuw.',
  },
  'common.reminders_quick_add_delete_failed_error': {
    'en-GB':
      'Oops! Something went wrong deleting your reminder. Please try again!',
    'nl-NL':
      'Oeps! Er ging iets fout tijdens het verwijderen van je moment. Probeer het even opnieuw.',
  },
  'common.reminder_modal_disclaimer.by_setting_up_a_reminder': {
    'en-GB':
      'By setting up a reminder, you are consenting to receive emails about this reminder.',
    'nl-NL':
      'Als je een moment toevoegt, geef je toestemming om e-mails te ontvangen over dit moment.',
  },
  'common.reminder_modal_disclaimer.view_our': {
    'en-GB': 'View our',
    'nl-NL': 'Lees ons',
  },
  'common.reminder_modal_disclaimer.privacy_notice_page': {
    'en-GB': 'Privacy Notice Page',
    'nl-NL': 'privacybeleid',
  },
  'common.reminder_modal_disclaimer.for_more_details': {
    'en-GB': 'for more details',
    'nl-NL': 'voor meer informatie',
  },
  'common.reminder_notification.set_prompt': {
    'en-GB':
      'Set a reminder below and never forget an important occasion again – phew!',
    'nl-NL':
      "Verjaardag of ander speciaal moment? Zet 'm in je kalender. Dan krijg je een seintje én korting als het bijna zover is!",
  },
  'common.reminder_notification.other_customers': {
    'en-GB': '<strong>80%</strong> of our customers have got a reminder.',
    'nl-NL': 'Greetz-kalender',
  },
  'common.reminder_card.error.title': {
    'en-GB': 'Please Try Again.',
    'nl-NL': 'Probeer het opnieuw.',
  },
  'common.reminders.quick_add': {
    'en-GB': 'Set More Reminders in 1 ',
    'nl-NL': 'Voeg meer momenten toe met 1 ',
  },
  'common.reminders.quick_add.tap': {
    'en-GB': 'Tap',
    'nl-NL': 'klik!',
  },
  'common.reminders.quick_add.click': {
    'en-GB': 'Click',
    'nl-NL': 'klik!',
  },
  'common.reminders.quick_add.added': {
    'en-GB': 'Added!',
    'nl-NL': 'Toegevoegd!',
  },
  'common.reminders.quick_add.mum_default_name': {
    'en-GB': 'Mum',
    'nl-NL': 'Mama',
  },
  'common.reminders.quick_add.dad_default_name': {
    'en-GB': 'Dad',
    'nl-NL': 'Pa',
  },
  'common.reminders.quick_add.valentine_default_name': {
    'en-GB': 'My Valentine',
    'nl-NL': 'Mijn Valentijn',
  },
  ...SOFT_OPT_IN_REMINDER_TEXT,
  ...DATE_TEXT,
}

export const useLocaleText = createLocaleTextHook({
  dictionary: REMINDER_TEXT,
})
