import type { Schema } from './types'

export const shared: Schema = {
  'digital-gift-card-options-free-ecard': {
    default: false,
  },
  'digital-gifts-dynamic-card-options': {
    default: false,
  },
  'digital-gifts-with-ecards': {
    default: false,
  },
  'gift-cards-with-ecards': {
    default: false,
  },
  'modular-digital-gift-pdp': {
    default: false,
  },
  'search-show-collage-maker': {
    default: true,
  },
  'search-show-group-card-tile': {
    default: false,
  },
  'search-use-shared-ranking-v2': {
    default: false,
  },
  'search-use-vector-search': {
    default: false,
  },
  'show-promo-tile': {
    default: false,
  },
  'show-sponsored-products': {
    default: true,
  },
  'enable-fav-toast-notification': {
    default: false,
  },
  'explore-enable-dynamic-hero': {
    default: false,
  },
  'search-filters-menu-rebuild': {
    default: false,
  },
  'enable-image-carousel-variant': {
    default: false,
  },
  'loyalty-enable-member-pricing': {
    default: false,
  },
}
