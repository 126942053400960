import React, { useCallback } from 'react'
import { trackGAEvent } from './trackGAEvent'

export const useTrackGAEventOnce = () => {
  const [tracked, setTracked] = React.useState([] as string[])

  const trackGAEventOnce = useCallback(
    (event: Record<string, unknown>) => {
      if (!tracked.includes(JSON.stringify(event))) {
        trackGAEvent(event)
        setTracked([...tracked, JSON.stringify(event)])
      }
    },
    [tracked, setTracked],
  )

  const resetGATracking = () => setTracked([])

  return { trackGAEventOnce, resetGATracking }
}
