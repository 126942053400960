import { sanitizeStrings, trackGAEvent } from '@moonpig/web-core-analytics'
import { logger } from '@moonpig/web-core-monitoring'
import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Value = any

export const useTrackExperiment = ({
  key,
  variant,
}: {
  key: string
  variant?: string
}) => {
  useEffect(() => {
    if (!variant || isExperimentAlreadyTracked({ key, variant })) return
    trackGAEvent(buildExperimentEvent({ key, variant }))
  }, [key, variant])
}

const isExperimentAlreadyTracked = ({
  key,
  variant,
}: {
  key: string
  variant: string
}) => {
  if (!window.dataLayer) {
    return false
  }

  const stringifiedEvents = window.dataLayer?.map(event => {
    try {
      return JSON.stringify({ ...event, 'gtm.uniqueEventId': undefined })
    } catch (error) {
      logger.fixToday(
        'Error stringifying event',
        { event: stringifyRemovingCyclics(event) },
        error,
      )
    }

    return '{}'
  })

  const stringifiedExperimentEvent = JSON.stringify({
    ...buildExperimentEvent({ key, variant }),
    'gtm.uniqueEventId': undefined,
  })

  return stringifiedEvents.includes(stringifiedExperimentEvent)
}

// Stringifies objects replacing cyclic object values with a string: 'cylic object value replaced'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stringifyRemovingCyclics = (object: any) => {
  const seenValues: Value[] = []
  const replacer = (_key: string, value: Value) => {
    if (value != null && typeof value === 'object') {
      if (seenValues.indexOf(value) >= 0) {
        return 'cyclic object value replaced'
      }
      seenValues.push(value)
    }

    return value
  }

  return JSON.stringify(object, replacer)
}

export const buildExperimentEvent = ({
  key,
  variant,
}: {
  key: string
  variant: string
}) => {
  const sanitizedVariation = sanitizeStrings(variant)
  const variationLabel =
    sanitizedVariation === 'not bucketed'
      ? 'no cohort assigned'
      : sanitizedVariation
  return {
    event: 'abtest',
    event_data: {
      category: 'ab test',
      action: sanitizeStrings(key),
      label: `${variationLabel} | exposure`,
      non_interaction: true,
      value: undefined,
    },
  }
}
