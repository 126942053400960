import React, { FC, useRef } from 'react'
import Head from 'next/head'
import type { Analytics, GoogleTagManagerClient } from '@moonpig/web-core-types'
import { useAnalytics } from '@moonpig/web-core-stores'

import { PageLoadEventFields } from './types'
import { useTrackScreenView } from './useTrackScreenView'

/* istanbul ignore next */
const useForceLocationRefresh = (analytics: Analytics) => {
  const isFirstRun = useRef(true)
  React.useEffect(() => {
    if (isFirstRun) {
      isFirstRun.current = false
    } else {
      // eslint-disable-next-line no-self-assign
      window.location = window.location
    }
  }, [analytics])
  return { isFirstRun }
}

const createScript = ({
  containerId,
  host,
}: {
  containerId: string
  host: string
}) => {
  return {
    gtmScript: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(), event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://${host}/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${containerId}');
    `,
    noScriptIframeSource: `https://${host}/ns.html?id=${containerId}`,
  }
}

type PageTracking = {
  type: string
  disableDefaultPageTracking?: boolean
  sortby?: string
} & PageLoadEventFields

export const SetupGTM: FC<{
  pageTracking: PageTracking
  enableThirdPartyScripts?: boolean
}> = ({ pageTracking, enableThirdPartyScripts }) => {
  const analytics = useAnalytics()

  const settings: GoogleTagManagerClient = analytics.gtmClient

  useTrackScreenView(pageTracking)

  const { gtmScript, noScriptIframeSource } = createScript({
    containerId: settings.containerId,
    host: settings.host,
  })

  const { isFirstRun } = useForceLocationRefresh(analytics)

  // We only want to inject script if this is the components first ever mount
  // AND if we have a correct currentContainerId
  return isFirstRun.current &&
    settings.containerId &&
    enableThirdPartyScripts ? (
    <>
      <Head>
        {/* eslint-disable react/no-danger */}
        <script
          data-testid="gtm-script"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || []; ${gtmScript}`,
          }}
        />
        {/* Google Webmaster Tools site verification code */}
        {'verificationCode' in settings ? (
          /* istanbul ignore next */ <meta
            name="google-site-verification"
            content={settings.verificationCode}
          />
        ) : null}
      </Head>

      <noscript>
        <iframe
          title="googleTagManager"
          src={noScriptIframeSource}
          style={{ display: 'none', visibility: 'hidden' }}
        />
      </noscript>
    </>
  ) : null
}
