import { styled, breakpointUp } from '@moonpig/launchpad-utils'
import React from 'react'
import {
  ScreenReaderOnly,
  CustomLink,
  LinkAsType,
  Flex,
} from '@moonpig/launchpad-components'
import {
  IconSystemAvatar,
  IconSystemBasket,
  IconSystemReminder,
  IconSystemHeartOutline,
} from '@moonpig/launchpad-assets'
import { system as s } from '@moonpig/launchpad-system'
import { ICON_SIZE_PX } from '../constants'
import { Notifier } from '../Notifier/Notifier'
import { Tooltip } from '../Tooltip'
import { useLocaleText } from '../locale'

const StyledUtilityMenu = styled.div`
  display: flex;
  width: 100%;
  top: 4px;
  right: 4px;

  ${breakpointUp('md')} {
    position: relative;
  }
`

const StyledReminder = styled(Flex)`
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  position: relative;

  ${s({
    color: 'colorInteractionIcon',
    typography: 'typeBodyCaption',
    px: 4,
  })}

  &:hover {
    text-decoration: none;

    ${s({
      color: 'colorInteractionIconHover',
    })}
  }
`

const StyledUtilityLink = styled(CustomLink)<{ count?: number }>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 48px;
  position: relative;

  ${s({
    color: 'colorInteractionIcon',
    px: 4,
  })}

  &::after {
    content: ${({ count }) => (count && count > 0 ? `'${count}'` : undefined)};
    ${s({
      bgcolor: 'colorInteractionButton',
      typography: {
        xs: 'typeMobileDisplay06',
        md: 'typeDesktopBodyCaption',
      },
      color: 'white',
      borderRadius: 7,
    })}
    position: absolute;
    top: 0;
    left: 50%;
    min-width: 20px;
    text-align: center;
    height: 20px;
    line-height: 20px;
  }

  &:focus {
    ${s({ color: 'colorInteractionSelectedState' })};
  }

  &:hover {
    text-decoration: none;

    ${s({
      color: 'colorInteractionIconHover',
    })}
  }

  .text {
    ${s({
      typography: 'typeBodyCaption',
    })}

    ${breakpointUp('md')} {
      ${s({
        typography: 'typeBodyText',
      })}
    }
  }
`

type UtilityLinkProps = {
  to: string
  type: 'reminders' | 'account' | 'basket' | 'favourites'
  linkAs?: LinkAsType
  label: string
  count?: number
  tooltip?: string
}

export type UtilityNotifierProps = {
  notifications: React.ReactNode[]
  onClick: () => void
  onDismiss: (trackEvent: boolean) => void
  showNotificationBadge: boolean
}

export type UtilityMenuProps = {
  items: UtilityLinkProps[]
  notifier?: UtilityNotifierProps
}

const iconMap = {
  reminders: <IconSystemReminder width={ICON_SIZE_PX} height={ICON_SIZE_PX} />,
  account: <IconSystemAvatar width={ICON_SIZE_PX} height={ICON_SIZE_PX} />,
  favourites: (
    <IconSystemHeartOutline width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
  ),
  basket: <IconSystemBasket width={ICON_SIZE_PX} height={ICON_SIZE_PX} />,
}

export const UtilityMenu = ({ items, notifier }: UtilityMenuProps) => {
  const t = useLocaleText()

  return (
    <StyledUtilityMenu>
      {notifier && (
        <StyledReminder>
          <Notifier {...notifier} />
        </StyledReminder>
      )}
      {items.map(({ to, linkAs, label, type, count, tooltip }, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledUtilityLink key={index} to={to} linkAs={linkAs} count={count}>
          <Flex flexDirection="column" alignItems="center">
            {iconMap[type]}
            <span className="text">{label}</span>
            {count ? (
              <ScreenReaderOnly>
                {t('utility_menu.count', count)}
              </ScreenReaderOnly>
            ) : null}
          </Flex>
          {tooltip && <Tooltip text={tooltip} />}
        </StyledUtilityLink>
      ))}
    </StyledUtilityMenu>
  )
}
