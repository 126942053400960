import React, { FC } from 'react'
import { styled, breakpoint, css, breakpointUp } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box } from '@moonpig/launchpad-components'
import { NAV_BP, TRANSITON_TIME_MS } from '../constants'
import { useLocaleText } from '../locale'

const POSITION_TOP = 0
const POSITION_MIDDLE = 1
const POSITION_BOTTOM = 2

const StyledBurgerLine = styled.span<{ isOpen: boolean; position: number }>`
  ${({ isOpen, position }) => {
    if (!isOpen) return {}
    const rotation = '45deg'
    const top = '7px'
    switch (position) {
      case POSITION_TOP:
        return css`
          top: ${top};
          transform: rotate(${rotation});
        `
      case POSITION_MIDDLE:
        return css`
          opacity: 0;
          transform: rotate(${rotation});
        `
      case POSITION_BOTTOM:
        return css`
          top: -${top};
          transform: rotate(-${rotation});
        `
      /* istanbul ignore next */
      default:
        return null
    }
  }}
`

const StyledToggle = styled.button<{
  isOpen: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  min-width: 40px;
  height: 50px;
  text-indent: -999px;
  line-height: 0;

  ${breakpointUp('md')} {
    flex-direction: row;
    position: absolute;
    border: 2px solid;
    border-radius: 8px;
    top: 8px;
    left: 4px;
    height: 44px;

    &:focus {
      outline: 2px solid
        ${({ theme }) => theme.colors.colorInteractionFocusIndicator};
      outline-offset: 4px;
    }

    ${({ isOpen }) =>
      s({
        px: 4,
        ml: 6,
        borderColor: isOpen
          ? 'colorInteractionSelectedState'
          : 'colorInteractionIcon',
      })}
  }

  .line {
    ${({ isOpen }) =>
      s({
        bgcolor: isOpen
          ? 'colorInteractionSelectedState'
          : 'colorInteractionIcon',
      })}

    position: relative;
    display: block;
    width: 18px;
    height: 2px;
    margin: 5px auto 5px;
    border-radius: 2px;
    transition: transform ${TRANSITON_TIME_MS}, opacity ${TRANSITON_TIME_MS};
    user-select: none;
  }
  .text {
    text-indent: 0;

    ${({ isOpen }) =>
      s({
        typography: 'typeBodyCaption',
        color: isOpen
          ? 'colorInteractionSelectedState'
          : 'colorInteractionIcon',
      })}

    ${breakpointUp('md')} {
      ${s({
        typography: 'typeBodyLabel',
      })}
    }
  }
`

const StyledToggleWrapper = styled.div`
  display: flex;
  justify-content: center;
  font-size: 0;

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

export const Toggle: FC<
  React.PropsWithChildren<{
    isOpen: boolean
    as?:
      | keyof JSX.IntrinsicElements
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      | React.ComponentType<React.PropsWithChildren<any>>
    onClick: () => void
  }>
> = ({ isOpen, onClick, as }) => {
  const t = useLocaleText()

  return (
    <StyledToggleWrapper>
      <StyledToggle
        onClick={onClick}
        aria-haspopup
        aria-expanded={isOpen}
        aria-controls="mega-nav-content"
        as={as}
        isOpen={isOpen}
        data-testid="lp-nav-meganav-toggle"
      >
        <Box marginRight={{ xs: 0, md: 4 }}>
          {`${isOpen ? t('mega_nav.toggle.close') : t('mega_nav.toggle.open')}`}
          {[POSITION_TOP, POSITION_MIDDLE, POSITION_BOTTOM].map(position => (
            <StyledBurgerLine
              key={position}
              className="line"
              position={position}
              isOpen={isOpen}
            />
          ))}
        </Box>
        {<span className="text">{t('mega_nav.toggle.title')}</span>}
      </StyledToggle>
    </StyledToggleWrapper>
  )
}
