import {
  TrackingEvent,
  NavigationEvent,
  createNavigationEvent,
} from '@moonpig/web-core-analytics'
import { absToRelUrl } from '@moonpig/web-core-utils'
import { createLinkHref } from '@moonpig/web-core-link'
import { makeNavTrackingButton } from './makeNavTrackingButton'

export type NavItem = {
  to: string
  label: string
  icon: string
  dropdown: {
    title: string
    items: {
      to: string
      label: string
    }[]
  }[]
}

const buildTrackEvent = (label: string): NavigationEvent => {
  return createNavigationEvent({
    action: 'header menu',
    label,
  })
}

const createHref = (url: string, trackEvent?: TrackingEvent) => {
  return createLinkHref({ url: absToRelUrl(url), trackEvent })
}

const buildTrackedNavItems = (items: NavItem[]) => {
  return items.map(item => ({
    label: item.label,
    icon: item.icon,
    href: createHref(item.to, buildTrackEvent(item.label)),
    buttonAs: makeNavTrackingButton(item.label),
    dropdown: item.dropdown.map(dropdown => ({
      ...dropdown,
      buttonAs: makeNavTrackingButton(`${item.label} | ${dropdown.title}`),
      items: dropdown.items.map(dropdownItem => ({
        label: dropdownItem.label,
        href: createHref(
          dropdownItem.to,
          buildTrackEvent(
            `${item.label} | ${dropdown.title} | ${dropdownItem.label}`,
          ),
        ),
      })),
    })),
  }))
}

export { buildTrackedNavItems }
