export enum EventType {
  SelectContent = 'select_content',
  Impression = 'impression',
}

export enum EventAction {
  View = 'view promotion',
  Dismiss = 'dismiss promotion',
  Click = 'download app',
}

type AppBannerEventParams = {
  eventType: EventType
  promotionTitle: string
  action: EventAction
  link?: string
}

export const createAppBannerEvent = ({
  eventType,
  promotionTitle,
  action,
  link,
}: AppBannerEventParams) => {
  const title = promotionTitle.toLowerCase()

  return {
    event: eventType,
    content_data: {
      content_type: `promotion | ${action} | smart banner | ${title}`,
      item_id: link,
    },
    event_data: {
      category: 'promotion',
      action,
      label: link
        ? `smart banner | ${title} | ${link}`
        : `smart banner | ${title}`,
      non_interaction: true,
      value: undefined,
    },
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}
