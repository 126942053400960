import React, { memo, useRef, useState } from 'react'
import { ApolloError, useLazyQuery } from '@moonpig/web-core-graphql'
import { useRouter, RouterType } from '@moonpig/web-core-routing'
import { SuggesterType, DepartmentsEnum } from '@moonpig/web-core-types-graphql'
import { useExperiments } from '@moonpig/web-core-experiments'
import { styled, breakpointDown } from '@moonpig/launchpad-utils'
import { getParentDepartment } from '@moonpig/web-core-utils'
import type {
  SubmitItemType,
  ReminderItemType,
  UrlSuggestionItemType,
  SuggestionItemType,
  RecentSearchType,
} from '@moonpig/web-core-nav'
import { GetSearchSuggestionsQuery } from './suggestions/__generated__/Suggestions'
import { useLocaleText } from '../SearchBar.locale'
import {
  appendToSearchHistory,
  clearSearchHistory,
  getSearchHistory,
  removeSearchHistoryItem,
} from './searchHistory'
import {
  FlatNavigationalSuggestion,
  getNavigationalSuggestions,
} from './navigationalSuggestions'
import {
  GetSearchSuggestionsGQL,
  SUGGESTIONS_LIMIT,
  SearchSuggestion,
  departmentalTransformation,
} from './suggestions/Suggestions'
import { TrackedSearch } from './TrackedSearch'
import { useSearchInspirations } from './searchInspirations'
import { useSearchReminders } from './searchReminders'

export const MAX_POPULAR_SEARCHES = 3
export const MAX_RECENT_SEARCHES = 3
export const DEFAULT_RECENT_SEARCH_DEPARTMENT = 'all_cards'

const StyledDiv = styled.div`
  ${breakpointDown('md')} {
    z-index: 99;
    position: relative;
  }
`

type SearchDepartment = {
  name: string
  title: string
}

type SearchBarProps = {
  searchDepartment?: SearchDepartment
  searchTerm?: string
  onApolloError?: (error: ApolloError) => void
}

const redirectToSearchPage = ({
  value,
  searchKey,
  searchDepartment,
  suggestionDepartment,
  router,
}: {
  value: string
  searchKey?: string
  searchDepartment?: SearchDepartment
  suggestionDepartment?: SearchDepartment
  router: RouterType
}) => {
  const { params } = router.getCurrentRoute<'content'>()
  const { region } = params

  window.scrollTo(0, 0)

  const newParams = {
    ...params,
    offset: undefined,
    suggestion: undefined,
    parts: undefined,
    q: undefined,
    filters: undefined,
    region,
    ...{
      d:
        suggestionDepartment?.name ||
        searchDepartment?.name ||
        /* istanbul ignore next */ 'all_cards',
    },
    ...(searchKey && { suggestion: searchKey }),
    ...(!searchKey && { q: value }),
  }

  return router.push({
    name: 'search',
    params: newParams,
  })
}

const SearchBarComponent: React.FC<SearchBarProps> = ({
  searchDepartment,
  searchTerm,
  onApolloError,
}) => {
  const router = useRouter()
  const localeText = useLocaleText()
  const [suggestions, setSuggestions] = useState([] as SearchSuggestion[])
  const [navSuggestions, setNavSuggestions] = useState(
    [] as FlatNavigationalSuggestion[],
  )

  const matchSearchQueriesToFacetsEnabled =
    useExperiments('search-improved-nba') === 'Enabled'

  const searchValue = useRef('')

  const parentDepartment = getParentDepartment(
    searchDepartment?.name.toLocaleUpperCase() as DepartmentsEnum,
  )

  const [fetchSearchSuggestions] = useLazyQuery<GetSearchSuggestionsQuery>(
    GetSearchSuggestionsGQL,
    {
      onError: onApolloError,
      onCompleted: data => {
        const transformedSuggestions = departmentalTransformation(
          data.searchSuggest.suggestions,
          parentDepartment as DepartmentsEnum,
        )
        setSuggestions(transformedSuggestions)
      },
    },
  )

  const inspirations = useSearchInspirations()

  const reminders = useSearchReminders().map(x => ({
    id: x.id,
    value: x.label,
  }))

  const [recentSearches, setRecentSearches] = useState(
    getSearchHistory()
      .slice(0, MAX_RECENT_SEARCHES)
      .map<RecentSearchType>(x => ({
        department: x.department,
        searchTerm: x.searchTerm,
        value: x.searchTerm,
      })),
  )

  const removeRecentSearch = (recentSearch: RecentSearchType) => {
    const updatedRecentSearches = removeSearchHistoryItem(recentSearch)
      .slice(0, MAX_RECENT_SEARCHES)
      .map<RecentSearchType>(x => ({
        department: x.department,
        searchTerm: x.searchTerm,
        value: x.searchTerm,
      }))

    setRecentSearches(updatedRecentSearches)
  }

  const clearRecentSearches = () => {
    clearSearchHistory()
    setRecentSearches([])
  }

  const departmentInspirations = inspirations
    .filter(x => x.department === parentDepartment)
    .slice(0, MAX_POPULAR_SEARCHES)

  const transformNavSuggestions = (props: {
    searchTerm: string
    router: RouterType
  }) => {
    const { params } = router.getCurrentRoute<'content'>()
    const { region } = params
    const navigationalSuggestions = getNavigationalSuggestions(
      props.searchTerm,
      region,
    )
    const transformedNavSuggestions = navigationalSuggestions?.map(x => ({
      value: x.displayText,
      url: x.url(region),
      icon: x.icon,
    }))
    setNavSuggestions(transformedNavSuggestions ?? [])
  }

  const handleSubmit = (submitItem: SubmitItemType) => {
    const { itemType, value } = submitItem
    if (itemType === 'reminder') {
      const reminder = submitItem as ReminderItemType
      const { params } = router.getCurrentRoute<'content'>()

      return router.push({
        name: 'reminder-landing',
        params: {
          region: params.region,
          id: reminder.id,
        },
      })
    }

    if (submitItem.itemType === 'navigational') {
      const suggestion = submitItem as UrlSuggestionItemType
      window.location.assign(suggestion.url)
    }

    let searchKey: string | undefined
    let department: SearchDepartment | undefined

    if (itemType === 'suggestion') {
      const suggestion = submitItem as SuggestionItemType
      searchKey = suggestion.searchKey
      department = suggestion.department
    }

    if (value.trim()) {
      const updatedRecentSearches = (
        appendToSearchHistory({
          searchTerm: value.trim(),
          searchKey,
          department: DEFAULT_RECENT_SEARCH_DEPARTMENT,
        }) || []
      )
        .slice(0, MAX_RECENT_SEARCHES)
        .map<RecentSearchType>(x => ({
          department: x.department,
          searchTerm: x.searchTerm,
          value: x.searchTerm,
        }))

      setRecentSearches(updatedRecentSearches)
    }

    let matchedSearchKey = searchKey

    if (matchSearchQueriesToFacetsEnabled && !matchedSearchKey) {
      matchedSearchKey = matchSearchQueryToFacet()
    }

    redirectToSearchPage({
      value,
      searchKey: matchedSearchKey,
      searchDepartment,
      suggestionDepartment: department,
      router,
    })
  }

  const matchSearchQueryToFacet = (): string | undefined => {
    const suggestionMatchingSearchValue = suggestions.find(
      suggestion =>
        suggestion.value.toLowerCase() === searchValue.current.toLowerCase(),
    )
    return suggestionMatchingSearchValue?.searchKey
  }

  const handleKeyUp = (value: string) => {
    const minNumberOfCharacters = 1
    const shouldFetchSuggestions =
      value.length >= minNumberOfCharacters && searchValue.current !== value

    /* istanbul ignore else */
    if (shouldFetchSuggestions) {
      searchValue.current = value

      transformNavSuggestions({ searchTerm: value, router })

      fetchSearchSuggestions({
        variables: {
          searchTerm: value,
          limit: SUGGESTIONS_LIMIT,
          suggesterType: 'FACETS' as SuggesterType,
          loadCounts: true,
          experimentValues: `search-use-new-ia-index=true`,
        },
      })
    } else if (suggestions.length && value.length < minNumberOfCharacters) {
      setSuggestions([])
    }
  }

  return (
    <StyledDiv>
      <TrackedSearch
        items={suggestions}
        searchTerm={searchTerm}
        searchValue={searchValue.current}
        onSubmit={handleSubmit}
        onKeyUp={handleKeyUp}
        onFocus={() => {}}
        navigationalSuggestions={navSuggestions}
        navigationalHeaderText={localeText('navigational_suggestion_header')}
        maxNavigationalSuggestions={1}
        onSuggestionsVisible={() => {}}
        searchDepartment={searchDepartment}
        inspirations={departmentInspirations}
        onRemoveRecentSearch={removeRecentSearch}
        onClearRecentSearches={clearRecentSearches}
        onRemindersVisible={
          // istanbul ignore next
          () => {}
        }
        reminders={reminders}
        recentSearches={recentSearches}
      />
    </StyledDiv>
  )
}

export const SearchBar = memo(SearchBarComponent)
