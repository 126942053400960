import React, { useEffect, useState, useCallback } from 'react'
import { IconSystemReminder } from '@moonpig/launchpad-assets'
import {
  Box,
  IconButtonWithBadge,
  SideModal,
} from '@moonpig/launchpad-components'
import { useRouter } from '@moonpig/web-core-routing'
import { useLocaleText } from '../locale'

const ICON_SIZE_PX = '28px'

export type NotifierProps = {
  notifications: React.ReactNode[]
  onClick: () => void
  onDismiss: (trackEvent: boolean) => void
  showNotificationBadge: boolean
}

const Icon = () => (
  <IconSystemReminder width={ICON_SIZE_PX} height={ICON_SIZE_PX} />
)

export const Notifier: React.FC<React.PropsWithChildren<NotifierProps>> = ({
  notifications,
  onClick,
  onDismiss,
  showNotificationBadge,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const handleClick = useCallback(() => {
    onClick()
    setModalIsOpen(true)
  }, [onClick])

  const testId = 'lp-nav-notifications'
  const t = useLocaleText()
  const router = useRouter()
  const triggerRef = React.useRef<HTMLButtonElement | null>(null)

  useEffect(() => {
    /* istanbul ignore next */
    router.onRouteChangeComplete(() => {
      onDismiss(false)
      setModalIsOpen(false)
    })
    return () => {
      router.onRouteChangeComplete(/* istanbul ignore next */ () => {})
    }
  }, [router, onDismiss])

  return (
    <>
      <IconButtonWithBadge
        label={t('notifier.reminders')}
        icon={Icon}
        onClick={handleClick}
        data-testid={`${testId}-icon`}
        showNotificationBadge={showNotificationBadge}
        ref={triggerRef}
      />

      <SideModal
        isOpen={modalIsOpen}
        onDismiss={() => {
          onDismiss(true)
          setModalIsOpen(false)
        }}
        heading={t('notifier.notifications')}
        description={t('notifier.notifications_modal')}
        triggerRef={triggerRef}
      >
        <Box>
          {notifications.length > 0 ? (
            notifications.map((notification, index) => {
              const key = `notification-${index}`
              return (
                <Box mb={4} key={key}>
                  {notification}
                </Box>
              )
            })
          ) : (
            <p>{t('notifier.no_notifications')}</p>
          )}
        </Box>
      </SideModal>
    </>
  )
}
