/* eslint-disable camelcase */
import React from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { trackGAEvent } from './trackGAEvent'

type Promotion = {
  id: string
  name: string
  creative: string
  position: string
}

type PromoClick = {
  promotions: Promotion[]
}

type Products = {
  quantity: number
  id: string
  name: string
  price: string
  brand: string
  category: string
  variant: string
}

type Add = {
  products: Products[]
}

type Ecommerce = {
  promoClick?: PromoClick
  currencyCode?: string
  add?: Add
}

type ClickEventDataNewBuy = {
  page_data: {
    name: string
    platform: string
    type: string
  }
  event: string
  event_data: {
    action?: string
    category?: string
    label?: string
    non_interaction: boolean
  }
  _clear: boolean
  ecommerce: {
    currency: string
    items: {
      index: number
      item_brand: string
      item_category: string
      item_id: string
      item_list_name: string
      item_name: string
      item_variant: string
      quantity: number
      price: string
    }[]
  }
}

type Ga4ClickEventData = {
  event: string

  content_data: {
    content_type: string
  }

  // For GA3 backward compatibility
  event_data: {
    action: string
    category: string
    label: string
    non_interaction: boolean
    value: undefined
  }

  // Web only //
  discount_data: undefined
  ecommerce: undefined
  error_data: undefined
}

export type ClickEventData =
  | {
      event: string
      eventData: {
        name: string
        category: string
        action: string
        label: string
        interaction: number
      }
      productData?: string
      listData?: string
      ecommerce?: Ecommerce
    }
  | ClickEventDataNewBuy
  | Ga4ClickEventData

type TrackedAnchorProps = {
  className?: string
  target?: string
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
  children?: React.ReactNode
  to: string
  eventData: ClickEventData
}

type HandleTrackingProps = {
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  eventData: ClickEventData
  to: string
  deferNavigation: boolean
}

// Prevent passing through props which don't belong in the DOM
const StyledAnchor = styled.a``

export const handleTracking = ({
  event,
  to,
  eventData,
  deferNavigation,
}: HandleTrackingProps) => {
  let fallbackNavigationId: number | undefined
  const { ctrlKey, metaKey } = event
  // Backup navigation if the datalayer fails to fire 'eventCallback'
  const continueOnToLinkHref = () => {
    if (fallbackNavigationId) {
      window.clearTimeout(fallbackNavigationId)
    }

    return ctrlKey || metaKey ? window.open(to) : window.location.assign(to)
  }

  if (deferNavigation) {
    // Stop navigating...
    event.preventDefault()

    fallbackNavigationId = window.setTimeout(continueOnToLinkHref, 100)
  }

  // Do tracking...
  // Then navigate...
  trackGAEvent({
    ...eventData,
    eventCallback: deferNavigation ? continueOnToLinkHref : undefined,
  })
}

export const TrackedAnchor = React.forwardRef<
  HTMLAnchorElement,
  TrackedAnchorProps
>((props, ref) => {
  const {
    to,
    children,
    className,
    eventData,
    target,
    onClick: orginalOnClick = () => {},
    ...rest
  } = props
  return (
    <StyledAnchor
      className={className}
      href={to}
      target={target}
      onClick={event => {
        orginalOnClick(event)
        handleTracking({
          event,
          eventData,
          to,
          deferNavigation: target !== '_blank',
        })
      }}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledAnchor>
  )
})

TrackedAnchor.displayName = 'TrackedAnchor'
