/* eslint-disable @typescript-eslint/no-explicit-any */
type EventObject = { [key: string]: any }

type Value = string | Value[] | { [key: string]: Value }

const sanitizeValue = <T extends Value>(value: T): Value => {
  if (typeof value === 'string') {
    return value.replace(/%/g, '')
  }
  if (Array.isArray(value)) {
    return value.map(sanitizeValue)
  }
  if (typeof value === 'object' && value) {
    return Object.entries(value).reduce<{ [key: string]: Value }>(
      (acc, [key, item]) => {
        acc[key] = sanitizeValue(item)
        return acc
      },
      {},
    )
  }
  return value
}

export const trackGAEvent = (event: EventObject) => {
  if (typeof window !== 'undefined') {
    const sanitizedEvent = sanitizeValue(event)

    window.dataLayer = window.dataLayer || []

    if (typeof sanitizedEvent === 'object' && 'ecommerce' in sanitizedEvent) {
      window.dataLayer.push({ event: 'ecom_clear', ecommerce: null })
    }

    window.dataLayer.push(sanitizedEvent)
  }
}
