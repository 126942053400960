import React, { createContext, useContext, FC } from 'react'

type LoggedInContext = {
  loggedIn: boolean
}

type LoggedInProviderProps = {
  value: LoggedInContext
}

const loggedInContext = createContext<LoggedInContext>({
  loggedIn: false,
})

export const useLoggedIn = () => useContext(loggedInContext)

export const LoggedInProvider: FC<
  React.PropsWithChildren<LoggedInProviderProps>
> = ({ value, children }) => {
  return (
    <loggedInContext.Provider value={value}>
      {children}
    </loggedInContext.Provider>
  )
}
