import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { getGACustomerData } from './getGACustomerData'
import {
  NavigationEvent,
  PromoClickEvent,
  SeeMoreClickEvent,
  CustomisationCompletedEvent,
  PromoExperimentClickEvent,
  ProductSelectedEvent,
  ProductViewedEvent,
  SelectContentEvent,
} from './types'
import { addSpacesToCamelCase } from './helpers/addSpacesToCamelCase'
import { createGenericSelectContentEvent } from './createGenericSelectContentEvent'
import { getCamelCaseExperiments } from './helpers/getCamelCaseExperiments'

export type CustomisationCompletedEventProperties = {
  correlationId: string
  productId: string
  store?: string
  filters?: { facetKey: string }[]
  metaData?: { experiments: { [key: string]: string | undefined } }
  source?: string
  customerId?: string
}

export const createCustomisationCompletedEvent = (
  trackingData: CustomisationCompletedEventProperties,
): CustomisationCompletedEvent => ({
  event: 'CUSTOMISATION_COMPLETED',
  correlationId: trackingData.correlationId,
  productId: trackingData.productId,
  filters: trackingData.filters,
  store: trackingData.store,
  metaData: trackingData.metaData,
  source: trackingData.source,
  customerId: trackingData.customerId,
})

export type ProductSelectedEventProperties = {
  correlationId: string
  productId: string
  searchTerm: string
  department?: string[]
  filters?: { facetKey: string }[]
  store: string
  metaData?: { experiments: { [key: string]: string | undefined } }
  source?: string
  customerId?: string
}

export const createProductSelectedEvent = (
  trackingData: ProductSelectedEventProperties,
): ProductSelectedEvent => ({
  event: 'PRODUCT_SELECTED',
  correlationId: trackingData.correlationId,
  productId: trackingData.productId,
  searchTerm: trackingData.searchTerm,
  department: trackingData.department,
  filters: trackingData.filters,
  store: trackingData.store,
  metaData: trackingData.metaData
    ? {
        experiments: getCamelCaseExperiments(
          trackingData.metaData?.experiments,
        ),
      }
    : undefined,
  source: trackingData.source,
  customerId: trackingData.customerId,
})

export type ProductViewedEventProperties = {
  correlationId: string
  productId: string
  searchTerm: string
  department?: string[]
  filters?: { facetKey: string }[]
  store: string
  metaData?: { experiments: { [key: string]: string | undefined } }
  source?: string
  customerId?: string
}

export const createProductViewedEvent = (
  trackingData: ProductViewedEventProperties,
): ProductViewedEvent => ({
  event: 'PRODUCT_VIEWED',
  correlationId: trackingData.correlationId,
  productId: trackingData.productId,
  searchTerm: trackingData.searchTerm,
  department: trackingData.department,
  filters: trackingData.filters,
  store: trackingData.store,
  metaData: trackingData.metaData,
  source: trackingData.source,
  customerId: trackingData.customerId,
})

export type CreateNavigationEventProperties = {
  action: string
  label: string
}

export const createNavigationEvent = (
  trackingData: CreateNavigationEventProperties,
): NavigationEvent => ({
  event: 'select_content',
  content_data: {
    content_type:
      `navigation | ${trackingData.action} | ${trackingData.label}`.toLocaleLowerCase(),
    item_id: undefined,
  },
  event_data: {
    category: 'navigation',
    action: trackingData.action.toLocaleLowerCase(),
    label: trackingData.label.toLocaleLowerCase(),
    non_interaction: true,
    value: undefined,
  },
  discount_data: undefined,
  ecommerce: undefined,
  error_data: undefined,
})

export type CreateSeeMoreClickEventProperties = {
  label: string
}

export const createSeeMoreClickEvent = (
  trackingData: CreateSeeMoreClickEventProperties,
): SeeMoreClickEvent | SelectContentEvent => {
  return createGenericSelectContentEvent({
    eventAction: 'see more products',
    eventCategory: 'product action',
    eventLabel: trackingData.label,
  })
}

export const createPromoExperimentClickEvent = ({
  title,
  moduleType,
  moduleIndex,
  elementIndex,
  experimentVariantName,
  brand,
}: CreatePromoClickEventProperties & {
  experimentVariantName: string
  brand: string
}): PromoExperimentClickEvent => {
  const { id: mp_id, isLoggedIn: is_logged_in } = getGACustomerData(
    getBrowserCookies(),
  )

  const contentModuleType =
    moduleType === 'ModuleDynamicHero' ? 'dynamic hero module' : moduleType

  const promotionName = `${moduleType.toLocaleLowerCase()} | ${experimentVariantName.toLocaleLowerCase()}`
  const creativeName = title.replace(/\s/g, '_').toLowerCase()

  return {
    event: 'select_content',
    user_data: {
      is_logged_in,
      mp_id,
      ct_id: undefined,
    },
    ecommerce: {
      items: [
        {
          item_id: `${promotionName} | ${creativeName}`,
          affiliation: 'user',
          creative_name: creativeName,
          item_brand: brand,
          promotion_name: promotionName,
          creative_slot: moduleType.toLocaleLowerCase(),
          index: `index${moduleIndex}:${moduleType.toLocaleLowerCase()}:${elementIndex}`,
        },
      ],
    },
    content_data: {
      content_type:
        `promotion | select promotion | ${contentModuleType} | ${creativeName}`.toLocaleLowerCase(),
      item_id: experimentVariantName.toLocaleLowerCase(),
    },
    // For GA3 backward compatibility
    event_data: {
      category: 'promotion',
      action: 'select promotion',
      label:
        `${contentModuleType} | ${experimentVariantName} | ${creativeName}`.toLocaleLowerCase(),
      non_interaction: true,
      value: undefined,
    },
    error_data: undefined,
  }
}

type CreatePromoClickEventProperties = {
  title: string
  moduleType: string
  moduleIndex: number
  elementIndex?: number
  ctaTitle?: string
  crossPlatformTrackingId?: string
}

export const createPromoClickEvent = ({
  title,
  moduleType,
  moduleIndex,
  elementIndex = 0,
  ctaTitle,
  crossPlatformTrackingId,
}: CreatePromoClickEventProperties): PromoClickEvent => {
  const adjustedModuleIndex = crossPlatformTrackingId
    ? moduleIndex
    : moduleIndex + 1
  const adjustedElementIndex = crossPlatformTrackingId
    ? elementIndex
    : elementIndex + 1

  const moduleNameWithSpaces = addSpacesToCamelCase(moduleType, {
    removeWord: 'Module',
  })
  const fullTitle = ctaTitle ? `${title} | ${ctaTitle}` : title
  const item_id = crossPlatformTrackingId
    ? `index:${adjustedModuleIndex} position:${adjustedElementIndex} | ${crossPlatformTrackingId}`
    : `index:${adjustedModuleIndex} position:${adjustedElementIndex}`
  return {
    event: 'select_content',
    content_data: {
      content_type:
        `promotion | select promotion | ${moduleNameWithSpaces} | ${fullTitle}`
          .toLocaleLowerCase()
          .substring(0, 100),
      item_id,
    },
    // For GA3 backward compatibility
    event_data: {
      category: 'promotion',
      action: 'select promotion',
      label: `${moduleNameWithSpaces} | ${fullTitle}`
        .toLocaleLowerCase()
        .substring(0, 100),
      non_interaction: true,
      value: undefined,
    },
    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}

type CreatePromoDeeplinkClickEventProperties = {
  title: string
  moduleType: string
  moduleIndex: number
  elementIndex: number
  crossPlatformTrackingId: string
}

export const createPromoExperimentDeeplinkClickEvent = ({
  title,
  moduleType,
  moduleIndex,
  elementIndex,
  crossPlatformTrackingId,
  experimentVariantName,
  brand,
}: CreatePromoDeeplinkClickEventProperties & {
  experimentVariantName: string
  brand: string
}): PromoExperimentClickEvent => {
  const { id: mp_id, isLoggedIn: is_logged_in } = getGACustomerData(
    getBrowserCookies(),
  )

  const contentModuleType =
    moduleType === 'ModuleDynamicHero' ? 'dynamic hero module' : moduleType

  const promotionName = `${moduleType.toLocaleLowerCase()} | ${experimentVariantName.toLocaleLowerCase()}`
  const creativeName = title.replace(/\s/g, '_').toLowerCase()

  return {
    event: 'select_content',
    user_data: {
      is_logged_in,
      mp_id,
      ct_id: undefined,
    },
    ecommerce: {
      items: [
        {
          item_id: `${promotionName} | ${creativeName} | ${crossPlatformTrackingId}`,
          affiliation: 'user',
          creative_name: creativeName,
          item_brand: brand,
          promotion_name: promotionName,
          creative_slot: moduleType.toLocaleLowerCase(),
          index: `index${moduleIndex}:${moduleType.toLocaleLowerCase()}:${elementIndex}`,
        },
      ],
    },
    content_data: {
      content_type:
        `promotion | select promotion | ${contentModuleType} | ${creativeName}`.toLocaleLowerCase(),
      item_id: experimentVariantName.toLocaleLowerCase(),
    },
    // For GA3 backward compatibility
    event_data: {
      category: 'promotion',
      action: 'select promotion',
      label:
        `${contentModuleType} | ${experimentVariantName} | ${creativeName} | ${crossPlatformTrackingId}`.toLocaleLowerCase(),
      non_interaction: true,
      value: undefined,
    },
    error_data: undefined,
  }
}

type CreatePageLoadEventProperties = {
  pageName: string
  pageType: string
  totalItems?: number
  basketId?: string
  documentReferrer?: string
  documentTitle?: string
  documentURL?: string
}

export const createPageLoadEvent = ({
  pageName,
  pageType,
  totalItems,
  basketId,
  documentReferrer,
  documentTitle,
  documentURL,
}: CreatePageLoadEventProperties) => {
  const { id: mp_id, isLoggedIn: is_logged_in } = getGACustomerData(
    getBrowserCookies(),
  )

  return {
    event: 'page_load',
    event_data: undefined,
    user_data: {
      is_logged_in,
      mp_id,
      ct_id: undefined,
    },
    page_data: {
      name: pageName,
      platform: 'new',
      type: pageType,
      document_referrer: documentReferrer,
      document_title: documentTitle,
      document_url: documentURL,
    },
    error_data: undefined,
    basket_data: {
      id: basketId,
      size: totalItems,
    },
    ecommerce: undefined,
    _clear: true,
  }
}
