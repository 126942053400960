import React, { FC } from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpoint } from '@moonpig/launchpad-utils'
import { Container, Flex } from '@moonpig/launchpad-components'
import { IconSystemPadlock } from '@moonpig/launchpad-assets'

import { NAV_BP } from '../constants'
import { useLocaleText } from '../locale'

const StyledHeaderWrapper = styled.div`
  ${s({ bgcolor: 'colorBackground01', borderColor: 'borderFour' })}
  border-bottom: 2px solid
`

const StyledHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 48px;
  ${breakpoint(NAV_BP)} {
    height: 96px;
  }
`

const StyledLockIcon = styled(IconSystemPadlock)`
  position: relative;
  bottom: 2px;
  margin-left: 4px;
`

const StyledInfo = styled.span`
  ${s({
    typography: { xs: 'typeMobileDisplay06', [NAV_BP]: 'typeDesktopBodyLabel' },
  })}
`

type CheckoutHeaderProps = {
  logoLink: React.ReactNode
}

export const CheckoutHeader: FC<
  React.PropsWithChildren<CheckoutHeaderProps>
> = ({ logoLink }) => {
  const t = useLocaleText()

  return (
    <StyledHeaderWrapper>
      <Container limitWidth>
        <StyledHeader>
          {logoLink}
          <Flex alignItems="center">
            <StyledInfo>{t('checkout_header.secure_checkout')}</StyledInfo>
            <StyledLockIcon width={28} height={28} />
          </Flex>
        </StyledHeader>
      </Container>
    </StyledHeaderWrapper>
  )
}
