import { useRouter } from '@moonpig/web-core-routing'
import { isLoggedIn } from '@moonpig/web-core-auth'
import { getBrowserCookies } from '@moonpig/web-core-cookies'
import { useEffect, useRef, useState } from 'react'
import { isServer } from '@moonpig/web-core-utils'

type SessionDetails = {
  isCustomerLoggedIn: boolean
  isPageSessionValid: boolean
}

const validateSession = (
  pageLoggedIn: boolean,
  pageRequiresLogin: boolean,
): SessionDetails => {
  const isBrowserLoggedIn = isLoggedIn(getBrowserCookies())
  const isCustomerLoggedIn = isServer
    ? pageLoggedIn
    : pageLoggedIn && isBrowserLoggedIn
  const isPageSessionValid = !pageRequiresLogin || isCustomerLoggedIn
  return {
    isCustomerLoggedIn,
    isPageSessionValid,
  }
}

export const useLoginGuard = (
  pageLoggedIn: boolean,
  pageRequiresLogin: boolean,
): { isCustomerLoggedIn: boolean } => {
  const router = useRouter()
  const pageRef = useRef({
    pageLoggedIn,
    pageRequiresLogin,
  })

  const { isCustomerLoggedIn, isPageSessionValid } = validateSession(
    pageLoggedIn,
    pageRequiresLogin,
  )

  const [validSession, setValidSession] = useState(isPageSessionValid)
  const [loggedIn, setLoggedIn] = useState(isCustomerLoggedIn)

  useEffect(() => {
    const onPageShow = ({ persisted }: PageTransitionEvent) => {
      if (persisted) {
        const persistedSession = validateSession(
          pageRef.current.pageLoggedIn,
          pageRef.current.pageRequiresLogin,
        )
        setLoggedIn(persistedSession.isCustomerLoggedIn)
        setValidSession(persistedSession.isPageSessionValid)
      }
    }

    window.addEventListener('pageshow', onPageShow)
    return () => window.removeEventListener('pageshow', onPageShow)
  }, [])

  useEffect(() => {
    if (!validSession) {
      router.reload()
    }
  }, [validSession, router])

  return {
    isCustomerLoggedIn: loggedIn,
  }
}
