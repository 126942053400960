export const createGenericEvent = ({
  eventAction,
  eventLabel,
  eventCategory,
}: {
  eventLabel: string
  eventAction: string
  eventCategory: string
}) => {
  return {
    ecommerce: null,
    event: 'generic_event',
    event_data: {
      category: eventCategory,
      action: eventAction,
      label: eventLabel,
      non_interaction: true,
    },
  }
}
