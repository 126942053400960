import React, {
  FC,
  createRef,
  useEffect,
  memo,
  useState,
  useContext,
  useCallback,
} from 'react'
import { system as s } from '@moonpig/launchpad-system'
import { styled, breakpoint, breakpointDown } from '@moonpig/launchpad-utils'
import {
  Grid,
  Box,
  ScreenReaderOnly,
  Link,
} from '@moonpig/launchpad-components'
import { IconSystemChevronRight as IconChevron } from '@moonpig/launchpad-assets'
import { spacingPx } from '@moonpig/launchpad-theme'
import { SubDropdown } from './SubDropdown'
import { MegaNavItemProps } from './types'

import {
  NAV_BP,
  COLOR_LINK_HOVER,
  COLOR_KEYLINE,
  MOBILE_NAV_WIDTH,
  KEYLINE_SIZE,
  KEYLINE_SIZE_PX,
  COLOR_DEFAULT_TEXT,
  ICON_SIZE_PX,
} from '../constants'
import { HeaderRefContext } from '../HeaderWrapper'
import {
  MegaNavAction,
  CLOSE_DROPDOWN,
  useMegaNavContext,
} from './MegaNavContext'
import { useLocaleText } from '../locale'

type StyledDropdownContentProps = {
  dropdownHeightOffset: number
  isDesktopNav: boolean
}

const StyledDropdownContent = styled.div.attrs(
  ({ dropdownHeightOffset, isDesktopNav }: StyledDropdownContentProps) => ({
    style: {
      maxHeight: isDesktopNav
        ? `calc(100vh - ${dropdownHeightOffset}px)`
        : 'initial',
    },
  }),
)<StyledDropdownContentProps>`
  ${s({ bgcolor: 'colorBackground01' })}

  ${breakpoint(NAV_BP)} {
    overflow: auto;
    padding: ${spacingPx(10)} ${spacingPx(6)};
    box-shadow: 0 5px 10px -5px rgba(0, 0, 0, 0.3);
  }
`

const StyledMobileCloseButton = styled.button<{ isOpen: boolean }>`
  position: relative;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 16px;
  padding-right: 16px;
  width: ${MOBILE_NAV_WIDTH - KEYLINE_SIZE}px;
  border: 0;
  border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  color: ${COLOR_DEFAULT_TEXT};
  background-color: transparent;
  ${s({ typography: 'typeButtonLabel' })}
  cursor: pointer;

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledDesktopPrimaryLinkWrapper = styled.div`
  ${s({ pb: 8 })}
  text-align: center;

  ${breakpointDown(NAV_BP)} {
    display: none;
  }
`

const StyledDesktopPrimaryLink = styled(Link)`
  ${s({ py: 3 })}
  display: inline-block;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`

const StyledMobilePrimaryLink = styled(Link)`
  display: block;
  ${s({ typography: 'typeButtonLabel', px: 6, py: 5 })}
  border-bottom: ${KEYLINE_SIZE_PX} solid ${COLOR_KEYLINE};
  color: inherit;

  &:hover,
  &:focus {
    text-decoration: none;
    color: ${COLOR_LINK_HOVER};
  }

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

const StyledChevron = styled(IconChevron)`
  position: absolute;
  top: 50%;
  left: 12px;
  width: ${ICON_SIZE_PX};
  height: ${ICON_SIZE_PX};
  transform: rotate(180deg) translateY(50%);
  color: ${COLOR_DEFAULT_TEXT};

  ${breakpoint(NAV_BP)} {
    display: none;
  }
`

type DropdownContentProps = {
  label: string
  href: string
  isOpen?: boolean
  dropdownIndex: number
  buttonAs?:
    | keyof JSX.IntrinsicElements
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    | React.ComponentType<React.PropsWithChildren<any>>
  items: MegaNavItemProps['dropdown']
  forceRenderAllNavNodes?: boolean
}

type WrappedDropdownContentProps = DropdownContentProps & {
  isOpenedByKeyboardOrClick: boolean
  isDesktopNav: boolean
  dispatch: (action: MegaNavAction) => void
}

const WrappedDropdownContent: FC<
  React.PropsWithChildren<WrappedDropdownContentProps>
> = memo<WrappedDropdownContentProps>(
  ({
    label,
    isOpen,
    href,
    dropdownIndex,
    isOpenedByKeyboardOrClick,
    isDesktopNav,
    dispatch,
    buttonAs,
    items,
    forceRenderAllNavNodes,
  }) => {
    const node = createRef<HTMLAnchorElement>()
    const [focusedItemIndex, setFocusedItemIndex] = useState([-1, -1])
    const [dropdownHeightOffset, setDropdownHeightOffset] = useState(0)
    const headerNode =
      useContext<React.MutableRefObject<HTMLElement | null> | null>(
        HeaderRefContext,
      )
    const t = useLocaleText()

    useEffect(() => {
      if (
        isOpenedByKeyboardOrClick &&
        node.current &&
        focusedItemIndex[0] === -1 &&
        focusedItemIndex[1] === -1
      ) {
        node.current.focus()
      }
    }, [node, isOpenedByKeyboardOrClick, focusedItemIndex])

    useEffect(() => {
      if (!isDesktopNav) {
        return
      }

      /* istanbul ignore next */
      if (headerNode && headerNode.current) {
        const { height, y } = headerNode.current.getBoundingClientRect()
        setDropdownHeightOffset(height + y)
      }
    }, [headerNode, isDesktopNav])

    const lastSubDropdownIndex = items.length - 1
    const lastSubDropdown = items[lastSubDropdownIndex]
    const lastSubDropdownItemIndex =
      lastSubDropdown && lastSubDropdown.items.length - 1

    const handleDesktopPrimaryLinkKeydown = React.useCallback(
      (e: React.KeyboardEvent) => {
        /* istanbul ignore next */
        const { key } = e
        if (key === 'ArrowLeft' || key === 'ArrowUp') {
          e.preventDefault()

          setFocusedItemIndex([lastSubDropdownIndex, lastSubDropdownItemIndex])
        }

        if (key === 'ArrowRight' || key === 'ArrowDown') {
          e.preventDefault()
          setFocusedItemIndex([0, 0])
        }

        if (key === 'Tab') {
          e.preventDefault()
          return e.shiftKey
            ? setFocusedItemIndex([
                lastSubDropdownIndex,
                lastSubDropdownItemIndex,
              ])
            : setFocusedItemIndex([0, 0])
        }
      },
      [lastSubDropdownIndex, lastSubDropdownItemIndex],
    )

    const firstFocusedItemIndex = focusedItemIndex[0]
    const secondFocusedItemIndex = focusedItemIndex[1]

    const currentFocusedDropdown = items[firstFocusedItemIndex]
    const currentFocusedDropdownItemsLength =
      currentFocusedDropdown?.items?.length || 0
    const previousFocusedDropdown = items[firstFocusedItemIndex - 1]
    const previousFocusedDropdownItemsLength =
      previousFocusedDropdown?.items?.length || 0

    const isLastDropdown = firstFocusedItemIndex === items.length - 1
    const isFirstDropdown = firstFocusedItemIndex === 0

    const isFirstItemInDropdown = secondFocusedItemIndex === 0
    const isLastItemInDropdown =
      secondFocusedItemIndex + 1 === currentFocusedDropdownItemsLength

    const handleSubdropdownLinkKeyDown = useCallback(
      (e: React.KeyboardEvent) => {
        const moveToPreviousItem = () => {
          if (isFirstItemInDropdown) {
            return setFocusedItemIndex([
              firstFocusedItemIndex - 1,
              previousFocusedDropdownItemsLength - 1,
            ])
          }

          return setFocusedItemIndex([
            firstFocusedItemIndex,
            secondFocusedItemIndex - 1,
          ])
        }

        const moveToNextItem = () => {
          if (isLastDropdown && isLastItemInDropdown) {
            return setFocusedItemIndex([-1, -1])
          }

          if (isLastItemInDropdown) {
            return setFocusedItemIndex([firstFocusedItemIndex + 1, 0])
          }

          return setFocusedItemIndex([
            firstFocusedItemIndex,
            secondFocusedItemIndex + 1,
          ])
        }

        /* istanbul ignore next */
        const { key } = e
        switch (key) {
          case 'ArrowUp':
            e.preventDefault()
            return moveToPreviousItem()
          case 'ArrowDown':
            e.preventDefault()
            return moveToNextItem()
          case 'ArrowRight':
            e.preventDefault()
            if (isLastDropdown) {
              return setFocusedItemIndex([-1, -1])
            }

            return setFocusedItemIndex([firstFocusedItemIndex + 1, 0])
          case 'ArrowLeft':
            e.preventDefault()
            if (isFirstDropdown) {
              return setFocusedItemIndex([-1, -1])
            }

            return setFocusedItemIndex([firstFocusedItemIndex - 1, 0])
          case 'Tab':
            e.preventDefault()
            if (e.shiftKey) return moveToPreviousItem()
            return moveToNextItem()
          default:
            return undefined
        }
      },
      [
        firstFocusedItemIndex,
        isFirstDropdown,
        isFirstItemInDropdown,
        isLastDropdown,
        isLastItemInDropdown,
        previousFocusedDropdownItemsLength,
        secondFocusedItemIndex,
      ],
    )

    const handleCloseClick = React.useCallback(
      () => dispatch([CLOSE_DROPDOWN]),
      [dispatch],
    )
    return (
      <StyledDropdownContent
        data-testid={`lp-nav-dropdown-${dropdownIndex}-content`}
        dropdownHeightOffset={dropdownHeightOffset}
        isDesktopNav={isDesktopNav}
      >
        <StyledMobileCloseButton
          isOpen
          as={buttonAs}
          data-testid={`lp-nav-dropdown-${dropdownIndex}-mobile-close-button`}
          tabIndex={-1}
          onClick={handleCloseClick}
        >
          {label}
          <ScreenReaderOnly>
            {t('mega_nav.dropdown_content.close', label)}
          </ScreenReaderOnly>
          <StyledChevron aria-hidden />
        </StyledMobileCloseButton>
        {isOpen && isOpenedByKeyboardOrClick && isDesktopNav && (
          <StyledDesktopPrimaryLinkWrapper>
            <StyledDesktopPrimaryLink
              data-testid={`lp-nav-dropdown-${dropdownIndex}-landing-page-desktop-link`}
              ref={node}
              href={href}
              onKeyDown={handleDesktopPrimaryLinkKeydown}
            >
              {t('mega_nav.dropdown_content.primary_link', label)}
            </StyledDesktopPrimaryLink>
          </StyledDesktopPrimaryLinkWrapper>
        )}
        <Grid justifyContent="center">
          <Box width={{ xs: 1, [NAV_BP]: 11 / 12 }}>
            <Grid justifyContent="center" gap={{ xs: 0, [NAV_BP]: 8 }} as="ul">
              {items.map((subDropdown, subDropdownIndex) => (
                <SubDropdown
                  onLinkKeyDown={handleSubdropdownLinkKeyDown}
                  forceRenderAllNavNodes={forceRenderAllNavNodes}
                  isDesktopNav={isDesktopNav}
                  focusedItemIndex={
                    subDropdownIndex === focusedItemIndex[0]
                      ? focusedItemIndex[1]
                      : -1
                  }
                  // eslint-disable-next-line react/no-array-index-key
                  key={subDropdownIndex}
                  id={`${dropdownIndex}-${subDropdownIndex}`}
                  dropdownIndex={dropdownIndex}
                  subDropdown={subDropdown}
                />
              ))}
            </Grid>
          </Box>
        </Grid>
        {!isDesktopNav && isOpen && (
          <StyledMobilePrimaryLink
            data-testid={`lp-nav-dropdown-${dropdownIndex}-landing-page-mobile-link`}
            href={href}
          >
            {t('mega_nav.dropdown_content.primary_link', label)}
          </StyledMobilePrimaryLink>
        )}
      </StyledDropdownContent>
    )
  },
)

WrappedDropdownContent.displayName = 'WrappedDropdownContent'

export const DropdownContent: FC<
  React.PropsWithChildren<DropdownContentProps>
> = props => {
  const [state, dispatch] = useMegaNavContext()
  const { isOpenedByKeyboardOrClick = false, isDesktopNav = false } = state
  const wrappedProps = {
    ...props,
    isOpenedByKeyboardOrClick: !!isDesktopNav && isOpenedByKeyboardOrClick,
    isDesktopNav,
    dispatch,
  }
  return <WrappedDropdownContent {...wrappedProps} />
}
