import { contentSecurityPolicy as brandContentSecurityPolicy } from '@moonpig/web-core-brand/config'
import { PageContentSecurityPolicy } from '@moonpig/web-core-types'

export const defaultContentSecurityPolicy: PageContentSecurityPolicy = {
  'default-src': ["'none'"],
  'font-src': [
    "'self'",
    'data:',
    '*.gstatic.com',
    '*.hotjar.com',
    '*.hotjar.io',
    '*.taggstar.com',
  ].concat(brandContentSecurityPolicy['font-src']),
  'connect-src': [
    "'self'",
    'blob:',
    'moonpig.api.ssopt.net',
    'sc-static.net',
    'wss://*.hotjar.com',
    '*.adalyser.com',
    '*.adyen.com',
    '*.bing.com',
    '*.clarity.ms',
    '*.cookielaw.org',
    '*.criteo.com',
    '*.criteo.net',
    'data:',
    '*.doubleclick.net',
    '*.facebook.com',
    '*.google-analytics.com',
    '*.google.co.uk',
    '*.google.com',
    '*.google.com.au',
    '*.googleapis.com',
    '*.googletagmanager.com',
    '*.googlesyndication.com',
    '*.mplat-ppcprotect.com',
    '*.hotjar.com:*',
    '*.hotjar.com',
    '*.hotjar.io',
    '*.onetrust.com',
    '*.pangle-ads.com',
    '*.paypal.com',
    '*.pinterest.com',
    '*.reddit.com',
    '*.redditstatic.com',
    '*.sciencebehindecommerce.com',
    '*.snapchat.com',
    '*.speedcurve.com',
    '*.taboola.com',
    '*.teads.tv',
    '*.tiktok.com',
    '*.trackjs.com',
    '*.wepowerconnections.com',
    '*.taggstar.com',
  ].concat(brandContentSecurityPolicy['connect-src']),
  'frame-src': [
    '*.adyen.com',
    '*.adsrvr.org',
    '*.awin1.com',
    '*.criteo.com',
    '*.criteo.net',
    '*.doubleclick.net',
    '*.facebook.com',
    '*.googleadservices.com',
    '*.googlesyndication.com',
    '*.googletagmanager.com',
    '*.hotjar.com',
    '*.hotjar.io',
    '*.mplat-ppcprotect.com',
    '*.paypal.com',
    '*.paypalobjects.com',
    '*.pinterest.com',
    '*.pinterest.co.uk',
    '*.snapchat.com',
    '*.stripe.com',
    '*.trustpilot.com',
    '*.wepowerconnections.com',
    '*.youtube.com',
    '*.zenaps.com',
  ].concat(brandContentSecurityPolicy['frame-src']),
  'style-src': [
    "'unsafe-inline'",
    "'self'",
    '*.adyen.com',
    '*.google.co.uk',
    '*.google.com',
    '*.googleapis.com',
    '*.googletagmanager.com',
    '*.paypal.com',
    '*.taggstar.com',
  ].concat(brandContentSecurityPolicy['style-src']),
  'img-src': ['*', 'data:', 'blob:'].concat(
    brandContentSecurityPolicy['img-src'],
  ),
  'script-src': [
    "'self'",
    "'unsafe-inline'",
    "'unsafe-eval'", // Required for https://www.dwin1.com/12199.js
    'd3dh5c7rwzliwm.cloudfront.net',
    'd32106rlhdcogo.cloudfront.net',
    'dgf0rw7orw6vf.cloudfront.net',
    'sc-static.net',
    'unpkg.com',
    '*.adalyser.com',
    '*.adyen.com',
    '*.adsrvr.org',
    '*.awin1.com',
    '*.bing.com',
    '*.clarity.ms',
    '*.cookielaw.org',
    '*.criteo.com',
    '*.criteo.net',
    '*.doubleclick.net',
    '*.dwin1.com',
    '*.facebook.net',
    '*.google-analytics.com',
    '*.google.co.uk',
    '*.google.com',
    '*.google.com.au',
    '*.googleadservices.com',
    '*.googlesyndication.com',
    '*.googletagmanager.com',
    '*.googletagservices.com',
    '*.hotjar.com',
    '*.hotjar.io',
    '*.mplat-ppcprotect.com',
    '*.onetrust.com',
    '*.pangle-ads.com',
    '*.paypal.com',
    '*.paypalobjects.com',
    '*.pinimg.com',
    '*.pinterest.com',
    '*.quantserve.com',
    '*.quantcount.com',
    '*.reddit.com',
    '*.redditstatic.com',
    '*.sciencebehindecommerce.com',
    '*.snapchat.com',
    '*.speedcurve.com',
    '*.stripe.com',
    '*.taboola.com',
    '*.teads.tv',
    '*.tiktok.com',
    '*.trustpilot.com',
    '*.wepowerconnections.com',
    '*.unpkg.com',
    '*.youtube.com',
    '*.zenaps.com',
    '*.taggstar.com',
  ].concat(brandContentSecurityPolicy['script-src']),
  'media-src': ['blob:'].concat(brandContentSecurityPolicy['media-src']),
}

export const generateContentSecurityPolicy = (
  csp?: PageContentSecurityPolicy,
) => {
  return {
    ...defaultContentSecurityPolicy,
    ...(csp || {}),
  }
}

export const generateCspString = (
  contentSecurityPolicy: PageContentSecurityPolicy,
): string =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.entries(contentSecurityPolicy)
    .filter(([, value]) => value && value.length > 0)
    .map(([property, rules]) => `${property} ${rules.join(' ')}`)
    .join(';')
