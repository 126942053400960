import { ClickEventData } from '@moonpig/web-core-analytics'
import { UtilityName } from '../types'

export const createSelectStoreGAEvent = (storeValue: string) => {
  return {
    event: 'select_content',
    content_data: {
      content_type: 'header | change store | store selector',
      item_id: storeValue.toLocaleLowerCase(),
    },
    event_data: {
      category: 'header',
      action: 'change store',
      label: `header | store selector | ${storeValue.toLocaleLowerCase()}`,
      non_interaction: true,
      value: undefined,
    },
    error_data: undefined,
    ecommerce: undefined,
  }
}

export const createDismissStoreSelectorModalGAEvent = () => {
  return {
    event: 'select_content',

    content_data: {
      content_type: 'header | dismiss modal | store selector',
    },

    // For GA3 backward compatibility
    event_data: {
      action: 'dismiss modal',
      category: 'header',
      label: 'store selector',
      non_interaction: true,
      value: undefined,
    },
  }
}

export const createOpenStoreSelectorModalGAEvent = () => {
  return {
    event: 'select_content',

    content_data: {
      content_type: 'header | open modal | store selector',
    },

    // For GA3 backward compatibility
    event_data: {
      action: 'open modal',
      category: 'header',
      label: 'store selector',
      non_interaction: true,
      value: undefined,
    },
  }
}

export const createUtilitiesGaEvent = (
  utilityName: UtilityName,
): ClickEventData => {
  return {
    event: 'select_content',

    content_data: {
      content_type: `navigation | view ${utilityName} | header | icon`,
    },

    // For GA3 backward compatibility
    event_data: {
      action: `view ${utilityName}`,
      category: 'navigation',
      label: 'header | icon',
      non_interaction: true, // always true
      value: undefined,
    },

    // Web only //
    discount_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}
