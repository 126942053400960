import { createGenericSelectContentEvent } from '@moonpig/web-core-analytics'

export const createSearchGAEvent = ({
  eventAction,
  eventLabel,
}: {
  eventAction: string
  eventLabel?: string
}) => {
  return createGenericSelectContentEvent({
    eventCategory: 'search',
    eventAction,
    eventLabel: eventLabel || 'search input',
  })
}

/* istanbul ignore next */
export const createSearchGA4Event = ({
  event,
  eventAction,
  eventLabel,
  eventValue,
  index,
  inputType,
  numberOfResults,
  productCategory,
  searchTerm,
  subcategories,
}: {
  event: string
  eventAction: string
  eventLabel: string
  eventValue?: string
  index?: string
  inputType?: string
  numberOfResults?: number
  productCategory?: string
  searchTerm?: string
  subcategories?: string
}) => {
  return {
    event,
    search_data: {
      index,
      input_type: inputType,
      number_of_results: numberOfResults,
      product_category: productCategory,
      search_term: searchTerm,
      subcategories,
    },
    event_data: {
      category: 'search',
      action: eventAction,
      label: eventLabel,
      value: eventValue,
      non_interaction: true,
    },
    content_data: undefined,
    ecommerce: undefined,
    error_data: undefined,
  }
}
