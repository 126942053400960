import React from 'react'
import { Box, SecondaryButton } from '@moonpig/launchpad-components'
import { Occasion } from '@moonpig/web-shared-types-graphql/graphqlTypes'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { IconSystemTickFilled } from '@moonpig/launchpad-assets'
import { useLocaleText } from '../../localisation'
import { OccasionIcon } from '../OccasionIcon'

export type QuickAddButtonProps = {
  occasion: Occasion
  checked: boolean
  loading: boolean
  disabled: boolean
  onToggle: () => void
  toggledLabelOverride?: string
}

const StyledSecondaryButton = styled(SecondaryButton)<{ checked: boolean }>`
  border-radius: 40px;
  margin-bottom: 8px;
  margin-right: 8px;
  position: relative;
  ${({ checked }) =>
    s({
      borderColor: checked ? 'colorInteractionButton' : 'colorInteractionIcon',
      color: checked ? 'colorInteractionButton' : 'colorInteractionIcon',
    })};
`

const StyledIconContainer = styled(Box)`
  position: absolute;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  top: -10px;
  right: -5px;
`

export const QuickAddButtonV2: React.FC<QuickAddButtonProps> = ({
  occasion,
  onToggle,
  checked,
  disabled,
}) => {
  const localiseText = useLocaleText()
  const occasionText = localiseText('common.reminder.occasion', { occasion })
  return (
    <Box>
      <StyledSecondaryButton
        checked={checked}
        aria-label={`Add ${occasionText} reminder`}
        onClick={onToggle}
        disabled={disabled}
        data-testid="quick-add-button-text"
        leadingIcon={<OccasionIcon occasion={occasion} />}
      >
        {occasionText}
        {checked && (
          <StyledIconContainer
            data-testid="quick-add-tick"
            bgcolor={'colorPrimary03'}
          >
            <IconSystemTickFilled height={24} width={24} />
          </StyledIconContainer>
        )}
      </StyledSecondaryButton>
    </Box>
  )
}
