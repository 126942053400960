import React, { FC, useEffect } from 'react'
import { styled } from '@moonpig/launchpad-utils'
import { system as s } from '@moonpig/launchpad-system'
import { Box, Text } from '@moonpig/launchpad-components'
import { spacingPx } from '@moonpig/launchpad-theme'
import { SuggestionType } from './Suggestions/Suggestion'
import { NavigationalSuggestionType } from './NavigationalSuggestions/NavigationalSuggestion'
import { NavigationalSuggestions } from './NavigationalSuggestions/NavigationalSuggestions'
import { Suggestions } from './Suggestions/Suggestions'
import { SuggestionItemType, UrlSuggestionItemType } from './types'
import { useLocaleText } from '../locale'

type AutocompleteSearchBoxProps = {
  isOpen: boolean
  suggestions: SuggestionType[]
  navigationalSuggestions: NavigationalSuggestionType[]
  inputValue: string | null
  getItemProps: (props: {
    itemIndex: number
    item: SuggestionItemType | UrlSuggestionItemType
  }) => Record<string, unknown>
  getMenuProps: () => Record<string, unknown>
  highlightedIndex: number | null
  value: string
  onSuggestionsVisible: () => void
  maxSuggestions?: number
  maxNavigationalSuggestions?: number
  navigationalHeaderText?: string
} & React.HTMLProps<HTMLElement>

const MAX_SUGGESTIONS = 10
const MAX_NAVIGATIONAL_SUGGESTIONS = 1

const StyledContainer = styled.div.attrs(({ isOpen }: { isOpen: boolean }) => ({
  className: !isOpen && 'hidden',
}))<{ isOpen: boolean }>`
  position: relative;
  top: ${spacingPx(13)};
  z-index: 3;
  ${s({
    pb: 4,
  })};
  &.hidden {
    display: none;
  }
`

export const AutocompleteSearchBox: FC<
  React.PropsWithChildren<AutocompleteSearchBoxProps>
> = ({
  isOpen,
  suggestions,
  navigationalSuggestions,
  inputValue,
  getItemProps,
  getMenuProps,
  highlightedIndex,
  value,
  onSuggestionsVisible,
  maxSuggestions = MAX_SUGGESTIONS,
  maxNavigationalSuggestions = MAX_NAVIGATIONAL_SUGGESTIONS,
  navigationalHeaderText = '',
}) => {
  const t = useLocaleText()

  const suggestionsVisible =
    isOpen &&
    (suggestions.length || navigationalSuggestions.length) &&
    inputValue

  const showNavigationalSuggestions =
    navigationalSuggestions.length && suggestions.length < MAX_SUGGESTIONS - 1

  useEffect(() => {
    if (suggestionsVisible) {
      onSuggestionsVisible()
    }
  }, [onSuggestionsVisible, suggestionsVisible])

  return (
    <StyledContainer isOpen={suggestionsVisible}>
      {isOpen && suggestionsVisible ? (
        <div data-testid="lp-nav-autocomplete-searchbox">
          <Suggestions
            highlightedIndex={highlightedIndex}
            value={value}
            suggestions={suggestions}
            maxSuggestions={maxSuggestions}
            getItemProps={getItemProps}
            getMenuProps={getMenuProps}
          />
          {showNavigationalSuggestions ? (
            <>
              {
                <Box paddingLeft={5} paddingTop={4} paddingBottom={3}>
                  <Text typography={'typeBodyLabel'}>
                    {navigationalHeaderText === ''
                      ? t('search.navigational_suggestions_header')
                      : navigationalHeaderText}
                  </Text>
                </Box>
              }
              <NavigationalSuggestions
                navigationalSuggestions={navigationalSuggestions}
                maxNavigationalSuggestions={maxNavigationalSuggestions}
                getItemProps={getItemProps}
                getMenuProps={getMenuProps}
              />
            </>
          ) : null}
        </div>
      ) : null}
    </StyledContainer>
  )
}
